import { memo, useCallback, lazy, useState } from 'react';
import { cn, UniversalPortal } from '@divlab/divanui';
import { CSSTransition } from 'react-transition-group';

import Suspense from '@Components/Suspense';
import useCountryBasedData from '@Hooks/useCountryBasedData';
import useMedias from '@Hooks/useMedias';
import { useRequest } from '@Contexts/Request';
import BottomSheet from './elements/BottomSheet';
import styles from './Pin.module.css';

import type { FC, HTMLAttributes, MouseEvent, MutableRefObject, CSSProperties } from 'react';
import type { ProductData } from '@Types/Product';

export interface PinProps extends Omit<HTMLAttributes<HTMLDivElement>, 'onClick'> {
  className?: string;
  pinCoords: [number, number];
  product: ProductData;
  refGrid: MutableRefObject<HTMLDivElement>;
  animated?: boolean;
  analyticsData?: PinAnalytics;
  onClick?: (e: MouseEvent) => void;
  onClickProductLink?: () => void;
}

export type PinAnalytics = {
  label: string;
  listTitle: string;
  orderType: string;
};

const IdeasPopup = lazy(() => import('./elements/IdeasPopup'));
const halfPinWidth = 17;

const Pin: FC<PinProps> = (props) => {
  const {
    className,
    pinCoords,
    product,
    refGrid,
    animated = true,
    analyticsData,
    onClick,
    onClickProductLink,
    ...restProps
  } = props;
  const [visible, setVisible] = useState(false);
  const [popupPosition, setPopupPosition] = useState<CSSProperties>();
  const { isMobile } = useMedias();
  const { country } = useRequest();
  const [subscribed, setSubscribed] = useState(false);

  const popupWidth = useCountryBasedData({
    UZB: 400,
    default: 371,
  });
  const halfPopupWidth = Math.floor(popupWidth / 2);
  const [left, top] = pinCoords;
  const priceWithoutCurrency = country === 'UZB';

  const handleOpenInDesktop = useCallback(
    (e: MouseEvent) => {
      if (isMobile) return;

      const currentRect = e.currentTarget.getBoundingClientRect();
      const currentGrid = refGrid.current.getBoundingClientRect();

      let posLeft = 0;
      let posRight = 0;
      const popupHeight = 184;

      if (currentGrid.left + halfPopupWidth + halfPinWidth > currentRect.left) {
        posLeft = currentGrid.left + halfPopupWidth - halfPinWidth - currentRect.left;
      }

      if (currentRect.right - halfPinWidth + halfPopupWidth > currentGrid.right) {
        posRight = currentRect.right - currentGrid.right - halfPopupWidth + halfPinWidth;
      }

      const getPositionValue = (pos: number) =>
        pos < 0 || pos > 0 ? `${Math.floor(pos)}px` : 'auto';

      let position = {
        left: getPositionValue(posLeft),
        right: getPositionValue(posRight),
        top: null,
      };

      if (currentRect.top - currentGrid.top < popupHeight) {
        position = { ...position, top: 'calc(100% - 20px)' };
      }

      setPopupPosition(position);
      setVisible(true);
    },
    [refGrid, halfPopupWidth, isMobile],
  );

  const handleSubscribed = useCallback(() => {
    setSubscribed(true);
  }, []);

  const handleClose = useCallback(() => {
    setVisible(false);
  }, []);

  const handleClick = useCallback(
    (e: MouseEvent) => {
      if (onClick) onClick(e);

      if (!isMobile) return;

      setVisible(true);
    },
    [isMobile, onClick],
  );

  const commonProps = {
    product,
    analyticsData,
    withoutCurrency: priceWithoutCurrency,
    subscribed,
    onClickProductLink,
    onSubscribed: handleSubscribed,
  };

  return (
    <div
      className={cn(styles.wrapper, { [styles.active]: visible }, className)}
      style={{ top: `${top}%`, left: `${left}%`, right: 'unset', bottom: 'unset' }}
      data-testid='pin'
      onMouseEnter={handleOpenInDesktop}
      onMouseLeave={handleClose}
    >
      <div
        {...restProps}
        className={cn(styles.pin, { [styles.active]: visible, [styles.animated]: animated })}
        onClick={handleClick}
      />

      <CSSTransition
        classNames={{
          enterActive: styles.enterActive,
          enterDone: styles.enterDone,
          enter: styles.enter,
          exit: styles.exit,
          exitActive: styles.exitActive,
          exitDone: styles.exitDone,
        }}
        in={visible}
        unmountOnExit
        timeout={200}
      >
        <div
          className={styles.popup}
          style={{
            ...popupPosition,
            width: isMobile ? '100%' : `${popupWidth}px`,
            padding: popupPosition?.top ? `54px 0 0` : null,
          }}
        >
          <Suspense fallback={null}>
            {isMobile ? (
              <UniversalPortal condition={isMobile}>
                <BottomSheet minHeight={50} maxHeight={405} needBackdrop onCollapse={handleClose}>
                  <IdeasPopup {...commonProps} />
                </BottomSheet>
              </UniversalPortal>
            ) : (
              <IdeasPopup {...commonProps} />
            )}
          </Suspense>
        </div>
      </CSSTransition>
    </div>
  );
};

export default memo(Pin);
