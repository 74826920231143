import { useMemo } from 'react';

import useCountryBasedData from '@Hooks/useCountryBasedData';
import useTranslation from '@Queries/useTranslation';
import Link from '@Navigation/Link';

import type { CountryBasedData } from '@Types/Base';
import type { InstagramAccountData } from '@Types/InstagramPost';

export const accounts: CountryBasedData<InstagramAccountData> = {
  BLR: {
    name: '@official_divan.by',
    link: 'https://www.instagram.com/official_divan.by/',
    hashtag: '#мебельначинаетсясдивана',
  },
  KAZ: {
    name: '@official_mebel.kz',
    link: 'https://www.instagram.com/official_mebel.kz/',
    hashtag: '#моямебелькз',
  },
  UZB: {
    name: '@official_divan.uz',
    link: 'https://www.instagram.com/official_divan.uz/',
    hashtag: '#моямебельуз',
  },
};

export const domains: CountryBasedData<string> = {
  BLR: 'Divan.by',
  RUS: 'Divan.ru',
  KAZ: 'Mebel.kz',
  UZB: 'Divan.uz',
};

const useInstagram = () => {
  const account = useCountryBasedData(accounts);
  const domain = useCountryBasedData(domains);
  const { t } = useTranslation();

  const description = useMemo(
    () =>
      account ? (
        <>
          {`${t('ui.instagram.has-account.stylize')} ${domain} ${t(
            'ui.instagram.has-account.tag-us',
          )}`}

          <Link view='primary' target='_blank' to={account.link}>
            {account.name}
          </Link>
          {t('ui.instagram.has-account.add-hashtag')}
          <Link
            view='primary'
            target='_blank'
            to={`https://www.instagram.com/explore/tags/${account.hashtag.replace('#', '')}`}
          >
            {account.hashtag}
          </Link>
          {t('ui.instagram.has-account.public')}
        </>
      ) : (
        <>
          {t('ui.instagram.default.description')}
          <Link view='primary' to='/'>
            {domain}
          </Link>
          {'.'}
        </>
      ),
    [account, domain, t],
  );

  return { account, description };
};

export default useInstagram;
