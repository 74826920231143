import { cn } from '@divlab/divanui';

import Link from '@Navigation/Link';
import useTranslation from '@Queries/useTranslation';
import styles from './Target.module.css';

import type { InstagramAccountData } from '@Types/InstagramPost';
import type { FC, HTMLAttributes } from 'react';

interface TargetProps extends HTMLAttributes<HTMLDivElement> {
  account: InstagramAccountData;
}

const Target: FC<TargetProps> = (props) => {
  const { className, account, ...restProps } = props;
  const { t } = useTranslation();
  const title = t('ui.instagram.promo.want-your-photo');
  const textBefore = t('ui.instagram.promo.tag-us.before-link');
  const textAfter = t('ui.instagram.promo.tag-us.after-link');

  return (
    <div className={cn(styles.target, className)} {...restProps}>
      <div className={styles.container}>
        <div className={styles.title} dangerouslySetInnerHTML={{ __html: title }} />
        <div className={styles.text}>
          {textBefore}
          <Link view='native' to={account.link}>
            {account.name}
          </Link>
          <br />
          {textAfter}
        </div>
      </div>
    </div>
  );
};

export default Target;
