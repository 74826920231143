import { useCallback, useEffect, useRef, useState } from 'react';
import { cn, Touch } from '@divlab/divanui';

import clamp from '@Utils/clamp';
import styles from './BottomSheet.module.css';

import type { FC, HTMLAttributes, PropsWithChildren } from 'react';
import type { TouchEvent } from '@divlab/divanui';

export interface BottomSheetProps extends HTMLAttributes<HTMLElement>, PropsWithChildren {
  minHeight?: number;
  maxHeight?: number;
  needBackdrop?: boolean;
  onCollapse?: () => void;
}

type BottomSheetState = 'collapsed' | 'expanded';

const minShiftHeight = 50;

const BottomSheet: FC<BottomSheetProps> = (props) => {
  const { className, minHeight, maxHeight, children, needBackdrop, onCollapse, ...restProps } =
    props;
  const [state, setState] = useState<BottomSheetState>('collapsed');
  const [shift, setShift] = useState(maxHeight);
  const [shiftOnStart, setShiftOnStart] = useState(maxHeight);
  const [animated, setAnimated] = useState(false);
  const maxShift = maxHeight - minHeight;
  const bodyRef = useRef<HTMLDivElement>(null);

  const handleStart = useCallback(() => {
    setAnimated(false);
    setShiftOnStart(shift);
  }, [shift]);

  const handleMove = useCallback(
    (event: TouchEvent) => {
      const newShift = clamp(0, shiftOnStart + event.shiftY, maxShift);

      setShift(newShift);
    },
    [maxShift, shiftOnStart],
  );

  const handleClose = useCallback(() => {
    setAnimated(true);
    setShift(maxShift);
    setState('collapsed');

    if (onCollapse) onCollapse();
    if (bodyRef.current) bodyRef.current.scrollTop = 0;
  }, [maxShift, onCollapse]);

  const handleEnd = useCallback(() => {
    setAnimated(true);
    const diffShift = shiftOnStart - shift;

    if (diffShift >= minShiftHeight || (diffShift < 0 && diffShift > -minShiftHeight)) {
      setShift(0);
      setState('expanded');
    } else if ((diffShift > 0 && diffShift <= minShiftHeight) || diffShift <= -minShiftHeight) {
      handleClose();
    }
  }, [shiftOnStart, shift, handleClose]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setAnimated(true);
      setShift(0);
      setState('expanded');
    }, 200);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className={styles.wrap}>
      {needBackdrop && <div className={styles.backdrop} onClick={handleClose} />}
      <div
        className={cn(styles.bottomSheet, className, {
          [styles.expanded]: state === 'expanded',
          [styles.animated]: animated,
        })}
        style={{
          minHeight: `${minHeight}px`,
          maxHeight: `${maxHeight}px`,
          transform: `translateY(${shift}px)`,
        }}
        {...restProps}
      >
        <Touch
          onStartY={handleStart}
          onMoveY={handleMove}
          onEndY={handleEnd}
          className={styles.handle}
        >
          <div className={styles.line} />
        </Touch>
        <div className={styles.body} ref={bodyRef}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default BottomSheet;
