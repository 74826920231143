import { useCallback, useEffect, useRef, useState } from 'react';
import { cn, Gallery, NavArrows, useMedias } from '@divlab/divanui';

import * as ModalWindows from '@Stores/ModalWindows';
import useInstagram from './useInstagram';
import Target from './elems/Target';
import Post from './elems/Post';
import Phone from '@Components/Phone';
import styles from './InstagramSection.module.css';

import type { FC } from 'react';
import type { IGalleryRef, SwiperClass } from '@divlab/divanui';
import type { InstagramProps } from './typings';
import type { InstagramPostData } from '@Types/InstagramPost';

const InstagramSection: FC<InstagramProps> = (props) => {
  const { className, title, titleTag = 'h2', posts, footnote, ...restProps } = props;
  const { account, description } = useInstagram();
  const { isDesktop } = useMedias();
  const Title = titleTag;
  const hasSlidesData = posts?.length > 0;
  const footerPhoneElement = /<a .*href="tel:.+>(.+)<\/a>/.exec(footnote);

  const galleryRef = useRef<IGalleryRef>(null);
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);

  const handlePostClick = useCallback((selectedPost: InstagramPostData) => {
    ModalWindows.open('InstagramPost', { selectedId: selectedPost.id });
  }, []);

  const handlePrev = useCallback(() => galleryRef.current?.slidePrev(), []);
  const handleNext = useCallback(() => galleryRef.current?.slideNext(), []);

  const handleSlideChange = useCallback((swiper: SwiperClass) => {
    setIsBeginning(swiper.isBeginning);
    setIsEnd(swiper.isEnd);
  }, []);

  useEffect(() => {
    const swiper = galleryRef.current?.swiper;

    if (swiper) {
      setIsBeginning(swiper.isBeginning);
      setIsEnd(swiper.isEnd);
    }
  }, []);

  return (
    <div className={cn(styles.wrapper, className)} {...restProps}>
      <div className={styles.container}>
        <div className={styles.textContainer}>
          {title && <Title className={styles.title}>{title}</Title>}
          {description && <div className={styles.description}>{description}</div>}
        </div>
        {hasSlidesData && !isDesktop && (
          <NavArrows
            onPrev={handlePrev}
            onNext={handleNext}
            prevDisabled={isBeginning}
            nextDisabled={isEnd}
          />
        )}
      </div>
      {hasSlidesData && (
        <Gallery
          ref={galleryRef}
          className={styles.gallery}
          cnSlide={styles.slide}
          scrollbar
          onSlideChange={handleSlideChange}
        >
          {account && <Target account={account} />}
          {posts.map((post) => (
            <Post key={post.id} post={post} onClick={() => handlePostClick(post)} />
          ))}
        </Gallery>
      )}

      {footerPhoneElement && (
        <div className={styles.footerText}>
          <span dangerouslySetInnerHTML={{ __html: footnote.split(footerPhoneElement[0])[0] }} />
          <Phone className={styles.footerPhoneLink} phone={footerPhoneElement[1]} />
          <span dangerouslySetInnerHTML={{ __html: footnote.split(footerPhoneElement[0])[1] }} />
        </div>
      )}

      {footnote && !footerPhoneElement && (
        <div className={styles.footerText} dangerouslySetInnerHTML={{ __html: footnote }} />
      )}
    </div>
  );
};

export default InstagramSection;
