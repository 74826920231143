import { cn } from '@divlab/divanui';

import Img from '@UI/Img';
import styles from './Post.module.css';

import type { InstagramPostData } from '@Types/InstagramPost';
import type { FC, HTMLAttributes } from 'react';

interface PostProps extends HTMLAttributes<HTMLDivElement> {
  post: InstagramPostData;
}

const Post: FC<PostProps> = (props) => {
  const { className, post, ...restProps } = props;

  return (
    <div className={cn(styles.post, className)} {...restProps}>
      <Img className={styles.image} src={post.img} alt={post.alt} />
      {post.author && <div className={styles.author}>{`@${post.author}`}</div>}
    </div>
  );
};

export default Post;
