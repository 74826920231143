import { memo } from 'react';

import GallerySection from '@Components/GallerySection';
import CrossSaleProductCard from '@Components/CrossSaleProductCard';

import type { FC, HTMLAttributes, ReactElement } from 'react';
import type { ProductData } from '@Types/Product';
import type { TitleTag } from '@Components/GallerySection';

export interface ProductsSectionProps extends HTMLAttributes<HTMLDivElement> {
  cnGallery?: string;
  cnSlide?: string;

  title?: string;
  description?: string;
  titleTag?: TitleTag;
  products: ProductData[];
  tabs?: boolean;
  analyticsTitle?: string;
  allTagsBottom?: boolean;
  needBuyButton?: boolean;

  renderProduct?(product: ProductData): ReactElement;
}

const ProductsSection: FC<ProductsSectionProps> = (props) => {
  const {
    cnGallery,
    cnSlide,
    title,
    description,
    titleTag,
    products,
    tabs,
    analyticsTitle,
    allTagsBottom,
    needBuyButton,
    renderProduct,
    ...restProps
  } = props;

  return (
    <GallerySection
      cnGallery={cnGallery}
      cnSlide={cnSlide}
      defaultSizes
      alignment='bottom'
      title={title}
      description={description}
      titleTag={titleTag}
      slides={products}
      renderSlide={(product) =>
        renderProduct ? (
          renderProduct(product)
        ) : (
          <CrossSaleProductCard
            product={product}
            allTagsBottom={allTagsBottom}
            needBuyButton={needBuyButton}
          />
        )
      }
      analytics={(product, index) => ({
        id: product.id,
        listTitle: analyticsTitle || title,
        card: product,
        cardType: 'product',
        position: index,
      })}
      fieldTab={tabs ? 'type' : undefined}
      {...restProps}
    />
  );
};

export default memo(ProductsSection);
